body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.print-only {
  display: none !important;
}

@media print {
  .no-print {
    display: none !important;
  }
  .print-only {
    display: block !important;
  }
  .highcharts-subtitle,
  .highcharts-exporting-group,
  .highcharts-legend-item-hidden {
    display: none !important;
  }

  hr {
    display: none !important;
  }

  .page {
    page-break-after: always;
    margin: 1px;
  }

  .MuiPaper-root {
    box-shadow: none !important;
  }
}
